// tslint:disable:max-line-length

import SvgIcon from 'material-ui/SvgIcon'
import { pure } from 'recompose'

const svg = (props: any) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 502 465" version="1">
      <path
        fill="#c3a985"
        d="M434 236c-1 15 0 19-2 33 16 1 30 3 46 8-6 8-17 16-25 20s-15 4-23 5l-10 36c18-3 35-10 52-22 12-8 18-18 25-30 3-5 5-9 5-13 0-11 0-12-3-20-3-5-11-8-14-9-19-4-33-5-51-8z"
      />
      <path fill="#c3a985" d="M446 214c0 118-100 213-223 213S0 332 0 214 100 0 223 0s223 96 223 214z" color="#000" />
      <path fill="#d9c9b3" d="M446 214c0 118-100 213-223 213S0 332 0 214 100 0 223 0s223 96 223 214z" color="#000" />
      <path fill="#d0bca0" d="M452 240c-5 0-8 26-3 27l29 6c6 1 14 3 18 0s2-16 1-19c-2-4-10-7-12-8l-33-6z" />
      <path
        fill="#e4d9c9"
        d="M224 3C103 3 4 101 4 217s98 208 219 208c100 0 185-62 207-147 0-2-1-4-2-2a210 210 0 0 1-205 145c-117 0-213-92-213-205S105 10 223 10c76 0 147 39 185 98 1 2 6 2 4-1A217 217 0 0 0 224 3z"
        color="#000"
      />
      <path fill="#c3a985" d="M437 216c0 113-96 205-214 205S10 329 10 216 105 12 223 12s214 91 214 204z" color="#000" />
      <path fill="#d9c9b3" d="M434 225c0 108-94 195-211 195S12 333 12 225 106 30 223 30s211 87 211 195z" color="#000" />
      <path
        fill="#c3a985"
        d="M0 222v8c0 130 100 235 223 235a229 229 0 0 0 223-243c-7 116-102 204-223 204S9 335 0 222z"
        color="#000"
      />
      <path fill="#c3a985" d="M411 164c0 80-84 144-187 144S38 244 38 164 121 19 224 19s187 65 187 145z" color="#000" />
      <path
        fill="#ceba9d"
        d="M397 246c11 25 7 36-7 47-17 14-21 47-42 71-13 25-35 24-45 29-22 19-47 7-77-1-14-6-45 15-73 0-28-1-46-21-53-42-19-6-25-32-26-60-12-10-28-25-18-45 7-15 26-58 25-83 0-25 8-31 23-36 5-18 53-52 67-52 30-5 32-10 47-3 16 8 51-8 81 2 14-1 36 29 48 55 6 20 31 33 29 51 2 28 21 18 27 50 3-11-12 26-8 17"
        color="#000"
      />
      <path
        fill="#b6966b"
        d="M421 339a247 247 0 0 1-226 107c-67-7-132-50-169-107 34 73 110 124 191 126a221 221 0 0 0 204-126z"
        color="#000"
      />
      <g color="#000">
        <path fill="#b6966b" d="M307 248a80 80 0 1 1-160 0 80 80 0 0 1 160 0z" />
        <path fill="#ceba9d" d="M303 248a76 76 0 1 1-153 0 76 76 0 0 1 153 0z" />
        <path
          fill="#e4d9c9"
          d="M152 219c-37 68 23 99 33 98a80 80 0 0 1-33-98zm153 46c-7 35-40 63-76 63 29 11 74-15 75-53l1-10z"
        />
        <path fill="#c3a985" d="M242 173a76 76 0 0 0-91 67c10-39 46-72 92-61 44 10 63 50 57 89a74 74 0 0 0-58-95z" />
      </g>
      <path
        fill="#e4d9c9"
        d="M453 246c-1 0-4 5-3 6 0 3 0 4 2 6 3 5 11 4 14 4 9 2 13-1 14-4 1-4-3-9-11-11-3-1-10-2-16-1z"
      />
      <path fill="#b6966b" d="M478 277c-6 8-17 16-25 20-6 3-12 4-17 4l-6 17c9-3 28-8 39-16s18-18 20-24h-8l-3-1z" />
      <path fill="#e4d9c9" d="M491 255c-5-2-6 5-3 8l3 1h3l-1-5-2-4z" />
      <g color="#000">
        <path fill="#410000" d="M416 227a193 193 0 0 1-385 0c0-107 86-194 192-194s193 87 193 194z" />
        <path fill="#300" d="M404 227c0 100-81 182-181 182S42 327 42 227c0-101 81-183 181-183s181 82 181 183z" />
        <path
          fill="none"
          d="M263 163c-23 0-47 4-71 13-84 31-129 108-100 172 18 40 60 65 109 71h4a190 190 0 0 0 8 1h1a196 196 0 0 0 9 0h1a227 227 0 0 0 14 0c73-6 134-52 162-117 6-22 5-46-5-68-20-46-73-73-132-72z"
        />
        <path
          fill="#6e3617"
          d="M182 49c-26 7-38 13-57 28-24 15-35 32-48 51-12 20-32 42-32 65-5 24 0 48 0 74 5 24 22 46 32 66 14 18 25 35 50 47 17 13 44 21 63 26a212 212 0 0 0 148-33c17-14 28-30 44-51 8-19 26-42 27-63 6-23-2-50-8-73 0-26-18-44-29-65-11-19-31-35-53-48-21-13-38-21-56-27-28-3-51-4-80 2l9-1m-3 25c23-7 42-3 67-1 16 5 32 10 53 21 21 10 26 27 42 44 10 18 23 35 33 55 0 22 3 42 2 63-7 21-13 41-25 57-8 16-18 31-39 43-11 11-42 22-61 27-23 6-40 3-60-1-19-5-36-11-56-21-14-12-42-23-51-41-16-15-15-38-16-59a87 87 0 0 1-8-62c4-19 11-40 25-57 6-17 27-30 44-43 16-11 31-20 55-27l-4 1"
        />
        <path fill="#8f451e" d="M51 222c1 3-2 6-7 7s-8 0-9-4 2-6 7-7 8 1 9 4z" />
        <path fill="#210000" d="M50 222c1 3-2 6-6 7s-7-1-8-4 2-6 6-7 7 1 8 4z" />
        <path d="M43 219l-3 2-2 1 1-2 4-1zm4 5l-1 1-2 2h-2l1 1h2l4-2v-2h-2z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M59 261c0 3-2 6-6 7s-8-1-8-4 2-6 6-6 7 0 8 3z" />
        <path fill="#210000" d="M58 261c0 3-2 5-5 6s-7 0-7-3 1-5 5-6 6 1 7 3z" />
        <path d="M51 259l-2 1c-2 0-1 2-2 1s0-1 2-2h2zm5 4l-2 1-1 1-2 1 1 1h1l4-2v-2h-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M71 299c1 5-3 9-9 11s-12-1-13-6 4-9 10-10 11 1 12 5z" />
        <path fill="#210000" d="M70 299c1 4-3 9-8 10s-10-1-11-5 2-8 8-10 10 1 11 5z" />
        <path d="M60 296l-3 2-4 2 2-3 5-1zm7 6l-2 2-3 2-2 1v1h3c2 0 4-2 5-3v-2l-1-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M75 176c1 5-4 12-12 13s-15-1-17-7 4-12 12-14 16 2 17 8z" />
        <path fill="#210000" d="M73 176c2 5-3 11-10 12s-14-1-15-6 4-11 11-13 13 2 14 7z" />
        <path
          d="M60 171c1 1-2 2-4 3l-5 2c-1-1 1-3 3-4l6-1zm9 9l-3 2-3 2-3 1c-1 1 0 2 1 2h3c3 0 6-2 7-4v-3h-2z"
          fill="#e3b7b7"
        />
        <path fill="#8f451e" d="M75 196c1 4-3 8-8 9s-11-1-12-5 3-8 8-9 11 1 12 5z" />
        <path fill="#210000" d="M74 196c0 4-3 8-8 9s-9-1-10-5 3-7 8-8 9 1 10 4z" />
        <path d="M65 193l-3 2-3 1 1-2c1-1 4-2 5-1zm6 6l-2 1-3 2-2 1 1 1h3l4-3v-2h-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M85 235c1 4-3 9-9 10s-12-1-13-5 3-10 9-11 12 1 13 6z" />
        <path fill="#210000" d="M84 235c0 4-3 8-9 9s-10-1-11-5 3-8 8-9 11 1 12 5z" />
        <path d="M73 231c1 1-1 1-3 2l-3 2c0-1 0-2 2-3l4-1zm7 7l-2 2-3 1-2 1 1 2h3l5-4v-2h-2z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M88 254c1 5-3 9-8 10s-11-1-12-5 3-9 8-10 11 1 12 5z" />
        <path fill="#210000" d="M87 255c1 3-2 7-7 9s-10-1-11-5 2-8 8-9 9 1 10 5z" />
        <path d="M78 251l-3 2-4 2 2-3 5-1zm6 6l-2 2-2 2h-3l1 2h3l4-3v-2l-1-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M104 333c1 3-2 7-7 8s-9-1-10-4 2-8 7-9 9 1 10 5z" />
        <path fill="#210000" d="M103 333c1 3-2 6-6 7s-9 0-9-4 2-6 6-7 9 1 9 4z" />
        <path d="M95 330l-2 2-3 1s0-2 2-2l3-1zm5 5l-1 2-2 1-2 1v1h3l4-3v-2h-2z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M107 353c1 3-2 6-6 7s-8-1-8-4 2-6 6-7 7 1 8 4z" />
        <path fill="#210000" d="M106 353c1 2-2 5-5 6s-7-1-8-3 2-6 6-6 7 0 7 3z" />
        <path d="M100 350l-3 2-2 1 2-2 3-1zm4 5l-1 1-2 1h-2l1 1h2l3-2v-1h-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M91 131c1 5-4 10-11 12s-13-1-14-6 4-11 10-12 13 1 15 6z" />
        <path fill="#210000" d="M89 131c1 5-3 10-9 11s-12-1-13-6 3-9 9-10 12 1 13 5z" />
        <path d="M78 127l-4 3-4 2 3-4 5-1zm7 8l-2 2-3 2h-3l1 2h4l5-4v-2h-2z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M142 365c1 5-3 10-10 11s-11-1-12-6 3-9 9-10 12 1 13 5z" />
        <path fill="#210000" d="M141 366c0 4-3 8-9 9s-10-1-11-5 3-8 8-10 11 2 12 6z" />
        <path d="M130 362l-3 2-3 2s0-2 2-3l4-1zm7 6l-2 2-3 2-2 1 1 1h3c2 0 4-2 5-3v-2l-2-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M113 106c1 5-3 10-11 12s-13-1-15-7 4-10 11-12 14 1 15 7z" />
        <path fill="#210000" d="M112 106c1 5-3 10-10 11s-12-1-13-6 3-10 9-11 13 1 14 6z" />
        <path d="M100 102l-4 2c-2 1-3 3-4 2s0-2 2-4 5-1 6 0zm8 7l-3 2-3 2-3 1 1 2h4l6-4v-2l-2-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M113 126c1 4-2 7-7 8s-9-1-10-4 3-7 7-8 9 1 10 4z" />
        <path fill="#210000" d="M112 126c1 3-2 7-6 7s-8 0-9-3 2-7 6-8 8 1 9 4z" />
        <path d="M104 123c1 1-1 1-2 2l-3 1c-1-1 0-1 2-2l3-1zm5 5l-1 2-2 1-2 1 1 1h2l4-3v-1l-2-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M131 346c1 4-3 8-8 9s-11-1-12-5 3-8 8-10 11 1 12 6z" />
        <path fill="#210000" d="M130 346c1 4-2 8-8 9s-9-1-10-5 2-8 7-9 10 1 11 5z" />
        <path d="M121 342l-3 2-4 2 2-3 5-1zm6 6l-2 2-3 2-2 1 1 1h3l4-3v-2l-1-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M171 379c1 4-3 8-8 10s-11-1-11-5 2-9 8-10 10 1 11 5z" />
        <path fill="#210000" d="M170 380c1 3-2 7-7 8s-10-1-10-5 2-7 7-8 9 1 10 5z" />
        <path d="M161 376l-3 2-3 2s0-2 2-3l4-1zm6 6l-2 2-2 1-2 1v1h3c2 0 4-1 4-3v-2h-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M81 281c1 4-3 9-9 10s-12-1-13-5 3-10 9-11 12 1 13 6z" />
        <path fill="#210000" d="M80 281c1 4-3 8-8 9s-11-1-12-5 3-8 9-9 10 1 11 5z" />
        <path d="M70 277l-3 2c-1 1-3 3-4 2s0-2 2-3l5-1zm7 7l-2 2-3 1-2 1v2h3l5-4v-2h-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M209 391c1 7-5 14-14 16s-18-2-19-8 4-14 13-16 18 2 20 8z" />
        <path fill="#210000" d="M207 392c1 6-4 12-12 14s-16-2-18-8 5-12 13-14 15 2 17 8z" />
        <path d="M192 386l-5 3-5 3c0-1 0-3 3-5s6-2 7-1zm9 10l-2 3-5 2-3 1 1 3h4c4-1 7-3 8-6v-3h-3z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M227 387c1 4-2 8-7 9s-9-1-10-5 2-7 7-8 9 1 10 4z" />
        <path fill="#210000" d="M226 387c1 4-2 7-6 8s-9-1-9-4 2-7 6-8 9 1 9 4z" />
        <path d="M218 384c1 1-1 1-2 2l-3 1 1-2 4-1zm5 6l-1 1-2 1-2 1v1h3l4-3v-1h-2z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M258 401c1 3-3 7-7 8s-10-1-10-4 2-8 7-9 9 1 10 5z" />
        <path fill="#210000" d="M257 401c1 3-2 7-6 7s-9 0-10-4 3-6 7-7 8 1 9 4z" />
        <path d="M249 398l-3 2-2 1 1-2 4-1zm5 5l-1 2-3 1-1 1v1h3l3-3v-2h-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M290 394c1 6-4 12-12 14s-16-2-17-8 4-12 12-14 16 2 17 8z" />
        <path fill="#210000" d="M289 394c1 5-4 11-11 12s-14-1-15-6 3-11 10-13 14 2 16 7z" />
        <path d="M275 389c1 1-2 1-4 3l-5 2c-1-1 0-3 3-4l6-1zm9 9l-3 2-3 2-3 1v2h4c3 0 6-2 7-4v-3h-2z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M310 369c2 5-3 10-10 12s-14-1-15-7 3-10 10-12 14 1 15 7z" />
        <path fill="#210000" d="M309 369c1 5-3 10-10 11s-12-1-13-6 3-10 10-11 12 1 13 6z" />
        <path
          d="M297 365l-4 2c-2 1-3 3-4 2s1-2 3-4 5-1 5 0zm8 7l-2 2-4 2-2 1v2h4c3-1 5-2 6-4v-2l-2-1z"
          fill="#e3b7b7"
        />
        <path fill="#8f451e" d="M301 64c1 6-5 13-13 15s-17-2-19-8 5-14 13-16 17 2 19 9z" />
        <path fill="#210000" d="M299 64c1 6-4 12-12 14s-15-2-16-8 4-12 12-13 15 1 16 7z" />
        <path
          d="M284 59l-4 3c-2 2-5 3-5 2s0-3 3-4l6-1zm10 9l-3 3-4 2-3 1c-1 1 0 2 1 2l4 1c3-1 6-3 7-6v-2l-2-1z"
          fill="#e3b7b7"
        />
        <path fill="#8f451e" d="M355 338c0 4-3 7-8 8s-9 0-10-4 3-7 8-8 9 1 10 4z" />
        <path fill="#210000" d="M354 338c0 4-3 7-7 8s-8-1-9-4 3-6 7-7 8 0 9 3z" />
        <path d="M346 336l-3 1-2 2 1-3h4zm5 5l-2 1-2 1-2 1 1 1h2c2 0 3-1 4-3v-1h-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M355 359l-4 4c-2 1-4-1-5-2s1-4 4-4 4 0 5 2z" />
        <path fill="#210000" d="M354 359c0 1-1 3-3 3l-4-1c0-1 1-4 3-4s4 0 4 2z" />
        <path d="M350 357l-1 1-1 1v-1l2-1zm3 3l-1 1h-1l-1 1h1l2-1v-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M323 79c1 3-2 6-6 7s-8-1-9-4 3-6 7-7 7 1 8 4z" />
        <path fill="#210000" d="M322 79c1 3-2 6-5 7s-7-1-8-4 2-5 6-6 7 1 7 3z" />
        <path d="M315 77l-2 1-2 1 1-2h3zm5 4l-1 1-2 2h-2l1 1h2l3-2v-2h-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M331 98c1 4-3 9-9 11s-12-2-13-6 3-10 9-11 12 1 13 6z" />
        <path fill="#210000" d="M330 98c1 4-3 9-8 10s-11-1-12-5 3-9 8-10 11 1 12 5z" />
        <path d="M320 94l-4 2-3 2c0-1 0-2 2-3l5-1zm7 7l-2 2-3 2h-3l1 2h3l5-4v-2h-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M382 312c2 5-4 12-12 13s-15-1-16-7 4-12 12-14 15 2 16 8z" />
        <path fill="#210000" d="M381 312c1 5-4 11-11 12s-14-1-15-6 4-11 11-13 14 2 15 7z" />
        <path d="M368 307l-5 3-4 2c0-1 0-3 2-4l7-1zm8 9l-2 2-4 2-3 1 1 2h4c3 0 5-2 6-4v-3h-2z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M414 229c0 3-3 7-8 8s-9-1-9-4 2-7 7-8 9 0 10 4z" />
        <path fill="#210000" d="M413 229c0 3-2 7-7 8s-8-1-9-4 3-7 7-8 8 1 9 4z" />
        <path d="M405 226l-3 2-2 1 1-2 4-1zm5 5l-2 2-2 1-1 1v1h2c2 0 4-2 4-3v-1l-1-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M396 268c0 4-3 8-9 9s-10-1-11-5 3-8 8-9 11 1 12 5z" />
        <path fill="#210000" d="M394 268c1 3-2 7-7 8s-9-1-10-4 2-8 7-9 10 1 10 5z" />
        <path d="M385 265l-3 1c-2 0-2 2-3 2l2-3h4zm6 5l-1 2-3 2h-2l1 2h2l5-4v-1l-2-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M397 288c1 3-2 6-6 7s-8-1-8-4 2-6 6-7 8 1 8 4z" />
        <path fill="#210000" d="M397 288c0 3-2 5-6 6s-7 0-7-3 1-6 5-6 7 0 8 3z" />
        <path d="M390 286l-2 1-3 1 2-2h3zm4 4l-1 1-2 1-1 1v1h2l3-3v-1h-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M384 132c1 4-3 9-9 10s-11-1-12-5 3-9 9-10 11 1 12 5z" />
        <path fill="#210000" d="M383 132c0 4-3 8-8 9s-10-1-11-5 3-7 8-9 10 1 11 5z" />
        <path d="M373 129l-3 2-3 1c0-1 0-2 2-3h4zm6 6l-2 2-2 1-2 1v1h3c2 0 4-1 5-3v-2h-2z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M402 164c1 5-3 10-10 11s-12-1-13-6 3-10 9-11 13 1 14 6z" />
        <path fill="#210000" d="M401 164c1 4-3 9-9 10s-11-1-12-5 3-9 9-10 11 1 12 5z" />
        <path d="M390 160l-4 3c-2 2-3 2-3 1s0-2 2-3l5-1zm7 7l-2 2-3 2-3 1 1 1h3c3 0 5-2 6-3v-3h-2z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M405 184c0 4-3 8-9 9s-10-1-11-5 3-8 8-9 11 1 12 5z" />
        <path fill="#210000" d="M403 184c1 4-2 7-7 9s-9-1-10-5 2-7 7-9 10 1 10 5z" />
        <path d="M394 181l-3 2c-2 1-2 2-3 1s0-2 2-3h4zm6 6l-1 1-3 2h-2l1 2h2l5-3v-2h-2z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M406 204c1 3-2 6-6 7s-7-1-8-4 2-6 6-7 7 1 8 4z" />
        <path fill="#210000" d="M405 204c1 3-2 5-5 6s-7-1-7-3 2-5 5-6 7 1 7 3z" />
        <path d="M399 202l-2 1-2 1 1-2h3zm4 4l-1 1-2 1-1 1v1h2l3-3v-1h-1z" fill="#e3b7b7" />
        <path
          fill="#6e3617"
          d="M266 83c-3 8 13 7 4 17 3 4-2 12-1 16-11 9-28 20-35 24l-21 12c-16 6-35 12-39 10-14 4-23 4-24 0-4-4-15-6-25-7 1-7-4-13-11-16-9-4 14-18 9-21-3-6 8-15 9-19 1-6 10-12 24-20 2-3 17-12 30-16 3-2 16-4 33-8 7-1 19 0 20 4 15-1 12 5 24 6 11 2-4 15 4 18"
        />
        <path fill="#8f451e" d="M164 102c-1 6-8 10-16 9s-14-7-14-13 8-10 16-9 15 7 14 13z" />
        <path fill="#210000" d="M162 102c0 5-7 9-14 8s-13-6-12-11 7-9 14-9 13 6 12 12z" />
        <path d="M151 93l-5 1h-5c-1-1 1-2 4-3s6 1 6 2zm6 10l-4 2-4 1h-3v2l4 1c3 1 6 0 8-2l1-2-2-2z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M154 120c0 5-7 9-14 8s-13-6-13-11 7-9 15-9 13 6 12 12z" />
        <path fill="#210000" d="M153 120c-1 4-7 8-13 7s-12-5-11-10 6-8 13-8 11 6 11 11z" />
        <path d="M143 111l-5 2h-4l3-3c2-1 5 1 6 1zm5 10l-3 2-4 1h-3v1l3 2 7-2c1-1 2-2 1-3l-1-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M145 141c-1 3-4 6-9 5s-7-3-7-7 4-5 9-4 7 3 7 6z" />
        <path fill="#210000" d="M144 141c0 3-4 5-8 4s-6-3-6-5 4-5 8-5 6 3 6 6z" />
        <path d="M138 136l-3 1h-2l2-1h3zm3 6l-2 1h-4l1 2h1l5-1v-1l-1-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M189 64c0 4-4 6-9 6s-8-4-8-7 5-6 10-6 8 4 7 7z" />
        <path fill="#210000" d="M188 64c0 3-4 6-8 5s-7-3-7-6 4-6 8-5 8 3 7 6z" />
        <path d="M182 59l-3 1h-3l2-1h4zm3 6l-2 1-2 1h-2v1l2 1 5-1v-2l-1-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M196 87c0 7-9 12-19 11s-17-8-16-15 9-13 19-12 17 8 16 16z" />
        <path fill="#210000" d="M194 86c0 7-8 11-17 10s-15-7-14-13 8-11 17-10 15 7 14 13z" />
        <path
          fill="#896262"
          d="M181 75c0 2-3 1-6 2l-6 1c0-1 1-3 5-4s6 0 7 1zm7 14l-4 2-5 1h-4v2l4 2c4 0 8-1 10-3l1-3-2-1z"
        />
        <path fill="#8f451e" d="M192 112c-1 5-7 9-15 8s-12-6-12-11 7-10 15-9 12 6 12 12z" />
        <path fill="#210000" d="M190 111c0 5-6 8-13 8s-11-5-10-10 6-8 12-8 12 6 11 10z" />
        <path
          d="M180 103c1 1-2 1-4 1l-5 1c-1-1 1-2 4-3s5 0 5 1zm5 10l-3 2h-6v2l3 1c3 1 5 0 7-2l1-2-2-1z"
          fill="#e3b7b7"
        />
        <path fill="#8f451e" d="M181 124c-1 5-6 8-13 7s-11-5-11-10 7-8 13-7 12 5 11 10z" />
        <path fill="#210000" d="M180 124c-1 4-6 7-12 7s-10-5-9-9 5-8 11-7 10 5 10 9z" />
        <path d="M171 117l-4 1h-4c0-1 1-2 3-2l5 1zm4 9l-2 1h-6v2l3 1c2 0 5 0 6-2l1-2h-2z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M196 148c-1 10-13 17-27 15s-25-11-23-21 13-18 27-16 24 11 23 22z" />
        <path fill="#210000" d="M193 147c-1 10-12 16-24 14s-22-10-21-19 12-15 25-14 22 10 20 19z" />
        <path
          d="M174 132c1 1-4 1-8 2s-8 3-9 1 2-4 7-5 10 0 10 2zm10 19l-6 2-7 2h-5v3c1 2 4 3 6 3 5 0 10-1 14-4 1-1 2-3 1-4-1-2-2-2-3-2z"
          fill="#e3b7b7"
        />
        <path fill="#8f451e" d="M209 69c0 2-3 3-6 3s-5-2-5-5 3-3 6-3 5 2 5 5z" />
        <path fill="#210000" d="M208 69c0 2-2 3-5 3s-4-3-4-4 2-4 5-3 5 2 4 4z" />
        <path d="M204 65l-2 1h-1l1-1h2zm2 4l-1 1h-3l1 1h4v-1l-1-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M212 87c0 3-4 5-8 4s-6-3-6-5 4-5 8-5 6 3 6 6z" />
        <path fill="#210000" d="M211 87c0 3-3 4-7 4s-6-3-5-5 3-5 6-4 6 2 6 5z" />
        <path d="M206 83h-2l-3 1 2-2c1-1 3 0 3 1zm2 5l-1 1h-3v1l1 1 4-1v-2h-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M212 109c0 3-4 5-8 5s-7-4-7-7 4-5 8-5 7 4 7 7z" />
        <path fill="#210000" d="M211 109c0 2-3 4-7 4s-6-3-6-6 3-4 7-4 7 3 6 6z" />
        <path d="M206 104l-3 1h-3c-1-1 1-1 2-1h4zm2 6l-1 1h-4v1l2 1 4-1v-2h-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M231 148c-1 8-10 13-21 12s-19-8-18-16 11-14 21-13 19 9 18 17z" />
        <path fill="#210000" d="M229 148c-1 7-9 12-19 11s-17-8-16-15 10-12 19-11 17 8 16 15z" />
        <path
          d="M214 136c1 1-3 1-6 1s-6 2-7 1 2-3 5-4 8 1 8 2zm8 14l-5 2-5 2h-4v2l5 2c4 0 8-1 10-3 1-1 2-2 1-3l-2-2z"
          fill="#e3b7b7"
        />
        <path fill="#8f451e" d="M250 73c-1 6-9 11-18 10s-16-7-15-14 9-12 18-11 16 8 15 15z" />
        <path fill="#210000" d="M248 72c0 6-8 11-16 10s-14-7-13-13 8-10 16-9 14 6 13 12z" />
        <path d="M236 62l-6 2h-5l4-3c3-1 6 0 7 1zm6 13l-4 1-4 1h-4v3l4 1 9-2c1-1 2-2 1-3l-2-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M241 96c-1 6-7 10-15 9s-13-6-13-12 8-9 15-9 14 6 13 12z" />
        <path fill="#210000" d="M239 96c0 5-6 8-13 8s-12-6-11-11 6-8 13-8 12 6 11 11z" />
        <path d="M229 88l-5 1h-4c0-1 1-2 3-2l6 1zm5 10l-3 1-4 1h-3l1 2 3 1c3 1 5 0 7-2l1-2-2-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M232 113c0 2-2 3-4 3s-4-2-4-4 3-2 5-2 3 2 3 3z" />
        <path fill="#210000" d="M232 113c0 2-2 2-4 2s-3-1-3-3 2-2 4-2 3 2 3 3z" />
        <path d="M229 111h-3l1-1 2 1zm1 3h-2v1h3v-1h-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M229 129c0 3-3 5-7 4s-6-3-6-5 4-5 7-4 7 2 6 5z" />
        <path fill="#210000" d="M229 129c-1 2-3 4-7 4s-5-3-5-5 3-4 6-4 6 3 6 5z" />
        <path d="M224 125l-2 1h-3l2-1h3zm2 5l-1 1h-3v1h1c2 1 3 0 4-1v-1h-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M267 75c0 3-4 5-8 5s-7-4-7-7 4-5 8-5 8 4 7 7z" />
        <path fill="#210000" d="M266 75c0 2-3 4-7 4s-7-3-6-6 3-5 7-4 7 3 6 6z" />
        <path d="M261 70l-3 1h-3c-1-1 1-1 2-2s3 1 4 1zm2 6l-1 1h-4v1l2 1 4-1v-2h-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M266 92c0 4-5 7-11 7s-10-5-9-9 5-7 11-7 10 5 9 9z" />
        <path fill="#210000" d="M265 92c0 4-5 6-10 6s-9-4-8-8 5-6 10-6 9 4 8 8z" />
        <path d="M257 86h-3c-2 0-3 2-4 1s1-2 3-2l4 1zm4 7l-2 1-3 1h-2v1l3 1c2 1 4 0 5-1l1-2-2-1z" fill="#e3b7b7" />
        <path fill="#8f451e" d="M272 116c-1 8-10 13-20 11s-17-8-16-15 9-12 19-11 18 8 17 15z" />
        <path fill="#210000" d="M270 116c-1 7-9 11-18 10s-15-7-14-14 8-11 17-10 16 7 15 14z" />
        <path
          d="M256 105c1 1-3 1-6 2h-6c-1-1 1-3 5-3l7 1zm7 13l-4 2-5 1h-4v3l5 2c3 0 7-1 9-3 1-1 2-2 1-3l-2-2z"
          fill="#e3b7b7"
        />
        <path fill="#8f451e" d="M259 136c0 3-4 6-9 5s-9-4-8-7 4-6 9-6 9 4 8 8z" />
        <path fill="#210000" d="M258 136c0 3-4 5-8 4s-8-3-7-6 4-6 8-5 8 3 7 7z" />
        <path d="M252 130l-3 1h-3l2-1h4zm3 7l-2 1h-4v1l2 1 5-1v-2h-1z" fill="#e3b7b7" />
        <path
          fill="#6e3617"
          d="M248 369c52-8 99-45 116-92 13-32 18-37 4-67-4-6-13-4-18-1-68 72-211 10-243 71-4 8-3 19 1 25 25 46 83 73 140 64z"
        />
      </g>
    </svg>
  </SvgIcon>
)

const CoffeeIcon: any = pure(svg)
CoffeeIcon.displayName = 'CoffeeIcon'
CoffeeIcon.muiName = 'SvgIcon'

export default CoffeeIcon
