// tslint:disable:max-line-length

import SvgIcon from 'material-ui/SvgIcon'
import { pure } from 'recompose'

const svg = (props: any) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.016 512.016">
      <g fill="#4caf50">
        <path d="M432 112.008c-6.464 0-12.576-3.968-14.976-10.368-3.136-8.288 1.056-17.504 9.344-20.608 40.512-15.2 54.368-53.888 54.496-54.272 2.944-8.352 12.192-12.768 20.384-9.856 8.352 2.912 12.736 12 9.888 20.352-.768 2.176-19.104 53.344-73.504 73.728a15.769 15.769 0 01-5.632 1.024z" />
        <path d="M496 176.008c-6.592 0-12.768-4.096-15.104-10.688-.416-1.12-14.88-39.456-54.528-54.336-8.288-3.104-12.48-12.32-9.344-20.608 3.104-8.288 12.384-12.448 20.608-9.344 54.4 20.416 72.704 71.552 73.504 73.728 2.88 8.352-1.536 17.44-9.888 20.352a15.736 15.736 0 01-5.248.896z" />
      </g>
      <path
        d="M336 134.376c-8.832 0-16 7.168-16 16v89.6c0 17.664-14.336 32-32 32h-16v-112c0-8.832-7.168-16-16-16s-16 7.168-16 16v112h-16c-17.632 0-32-14.336-32-32v-89.6c0-8.832-7.168-16-16-16s-16 7.168-16 16v89.6c0 35.296 28.704 64 64 64h16v176.032c0 8.832 7.168 16 16 16s16-7.168 16-16V303.976h16c35.296 0 64-28.704 64-64v-89.6c0-8.832-7.168-16-16-16z"
        fill="#cfd8dc"
      />
      <path
        d="M368 16.008c-3.008 0-5.984.064-8.608.48L43.168 48.232v.16C18.56 50.856 0 71.336 0 96.008s18.56 45.152 43.168 47.616l315.872 31.872c2.72.448 5.792.512 8.96.512 44.128 0 80-35.904 80-80s-35.872-80-80-80z"
        fill="#ff9800"
      />
      <g fill="#ffd54f">
        <path d="M122.048 78.856c1.952.8 3.968 1.152 5.952 1.152 6.336 0 12.352-3.808 14.848-10.048 4.192-10.496 7.36-21.536 9.888-32.736l-34.56 3.488c-1.664 5.792-2.816 11.808-5.024 17.344-3.296 8.192.704 17.504 8.896 20.8zM202.016 78.856c1.984.768 4 1.152 5.984 1.152 6.336 0 12.352-3.808 14.848-10.016 5.344-13.344 8.832-26.976 11.392-40.928l-33.088 3.296c-2.08 8.704-4.608 17.248-8 25.664-3.296 8.224.704 17.536 8.864 20.832zM298.912 22.568c-2.08 12-5.024 23.872-9.728 35.392-3.328 8.192.608 17.536 8.8 20.864 1.952.8 4 1.184 6.016 1.184 6.304 0 12.288-3.744 14.816-9.952 6.72-16.448 10.432-33.504 12.64-50.784l-32.544 3.296z" />
      </g>
    </svg>
  </SvgIcon>
)

const VegoIcon: any = pure(svg)
VegoIcon.displayName = 'VegoIcon'
VegoIcon.muiName = 'SvgIcon'

export default VegoIcon
